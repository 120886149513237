import { makeStyles } from '@material-ui/core';
import { paletteV2 } from '../../../theme/palette';

export const menuStyles = makeStyles(theme => ({
  baseItem: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    color: theme.palette.common.white,
    borderRadius: theme.spacing(0.5),
    '& > *': {
      padding: theme.spacing(1),
    },
    '&:not([data-disabled=""])': {
      backgroundColor: paletteV2.grey[900],
      '&:hover': {
        backgroundColor: paletteV2.grey[700],
        outline: 'none',
        cursor: 'pointer',
      },
      '&:focus-visible:not(&:hover)': {
        outline: `1px solid ${paletteV2.lime[100]}`,
        backgroundColor: paletteV2.grey[700],
      },
    },
  },
  label: {
    color: theme.palette.common.white,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  radioItem: {
    display: 'flex',
    borderRadius: theme.spacing(0.5),
    color: theme.palette.common.white,
  },
  favorite: {
    color: paletteV2.cherry[400],
    marginRight: '4px',
  },
  icon: {
    marginLeft: 'auto',
    marginRight: 0,
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  content: {
    ...theme.mixins.scrollBar,
    minWidth: `calc(var(--radix-dropdown-menu-trigger-width) - 12px)`,
    overflowY: 'auto',
    /**
     *  (Height of a single menu item) * (desired items to be displayed in menu)
     */
    maxHeight: theme.spacing(4) * 6.7,
    zIndex: theme.zIndex.tooltip,
  },
  checkbox: {
    color: theme.palette.common.white,
  },
  innerText: {
    padding: theme.spacing(0, 0, 0, 1),
  },
}));

export default menuStyles;
