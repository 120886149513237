import React from 'react';
import { Box, Button, ButtonProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Icon, { AvailableIconTypes } from '../../Icon';
import { paletteV2 } from '../../../theme/palette';
import Typography from '../../Typography';
import menuStyles from '../menus/styles';

interface SingleSelectMenuButtonProps extends ButtonProps {
  open: boolean;
  label: string;
  icon?: AvailableIconTypes;
  ariaLabel: string;
  isFavorite?: boolean;
  active?: boolean;
  /**
   * Renders elements to the right of the displayed text label
   *
   */
  children?: React.ReactNode;
}

export const useStyles = makeStyles(theme => ({
  menuTrigger: {
    width: '100%',
    borderRadius: theme.spacing(0.5),
    outline: `1px solid ${theme.palette.inputs.default}`,
    padding: theme.spacing(1, 1, 1, 1.5),
    fontWeight: 'normal',
    color: paletteV2.grey[75],
    outlineOffset: 1,
    cursor: 'pointer',
    '&:hover': {
      outline: `1px solid ${theme.palette.inputs.active}`,
    },
    '&[data-active="true"]': {
      color: paletteV2.grey[0],
    },
    '&[data-open="true"]': {
      color: paletteV2.grey[0],
    },
    '&.Mui-focusVisible, &[data-state="open"]': {
      outline: `1px solid ${theme.palette.primary.main}`,
      outlineOffset: 1,
    },
    '&.Mui-disabled': {
      color: paletteV2.grey[75],
      cursor: 'disabled',
    },
    '&:focus': {
      outline: `1px solid ${paletteV2.lime[100]}`,
      outlineOffset: 1,
    },
  },
  menuTriggerLabel: {
    justifyContent: 'start',
    fontSize: theme.spacing(1.5),
  },
  textSizeSmall: {
    padding: theme.spacing(0.75, 1),
  },
  chevronIcon: {
    color: paletteV2.grey[75],
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
  },
  open: {
    color: paletteV2.grey[0],
  },
}));

/**
 * Main button to display the currently selected option
 * Used with menu
 */
const MenuButton = React.forwardRef<
  HTMLButtonElement,
  SingleSelectMenuButtonProps
>((props, forwardRef) => {
  const {
    open,
    label,
    ariaLabel,
    icon,
    className,
    disabled,
    isFavorite,
    active,
    children,
    ...rest
  } = props;
  const classes = useStyles();
  const menuClasses = menuStyles();
  return (
    <Button
      {...rest}
      ref={forwardRef}
      aria-label={ariaLabel}
      disabled={disabled}
      classes={{
        label: classes.menuTriggerLabel,
        root: clsx(classes.menuTrigger, className),
        textSizeSmall: classes.textSizeSmall,
      }}
      data-open={open}
      data-active={active}
    >
      {icon && (
        <Box display="flex" mr={1}>
          <Icon type={icon} />
        </Box>
      )}
      <Typography variant="sm" className={menuClasses.label}>
        {label}
      </Typography>
      {isFavorite && (
        <Box display="flex" ml={1} alignItems="center">
          <Icon type="favoriteFilled" className={menuClasses.favorite} />
        </Box>
      )}
      {children}
      {!disabled && (
        <Icon
          type={!open ? 'chevronDown' : 'chevronUp'}
          className={clsx(menuClasses.icon, classes.chevronIcon, {
            [classes.open]: open,
          })}
        />
      )}
    </Button>
  );
});

export default MenuButton;
