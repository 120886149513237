import React from 'react';
import { Avatar, Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const DEFAULT_USER_PROFILE_IMAGE = `/static/images/null_profile.svg`;

const useStyles = makeStyles(theme => ({
  container: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    padding: 0,
    minWidth: 0,
    opacity: 0.4,
    '& .MuiButton-label': {
      width: 'unset',
    },
    '&.Mui-focusVisible': {
      ...theme.mixins.focusOutline,
      opacity: 1,
      borderRadius: `50%`,
    },
    '&:hover': {
      cursor: 'pointer',
      opacity: 1,
    },
  },
  open: {
    opacity: 1,
  },
}));

export interface UserAvatarButtonProps {
  open: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  imageSrc?: string;
}

const UserAvatarButton = React.forwardRef<
  HTMLButtonElement,
  UserAvatarButtonProps
>((props, forwardRef) => {
  const { open, onClick, imageSrc = DEFAULT_USER_PROFILE_IMAGE } = props;

  const classes = useStyles();
  return (
    <Button
      ref={forwardRef}
      tabIndex={0}
      className={clsx(classes.container, { [classes.open]: open })}
      data-e2e="action-user-profile-menu-account"
      onClick={onClick}
      aria-label="user profile menu"
    >
      <Avatar src={imageSrc} />
    </Button>
  );
});

export default UserAvatarButton;
