import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Head from 'next/head';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    '&.loading': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.background.default,
    },
  },
  loadingIcon: {
    animation: '$spin 2s linear infinite',
    border: `2px solid transparent`,
    borderTop: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    boxSizing: 'border-box',
    height: theme.spacing(4),
    position: 'absolute',
    right: '50%',
    top: '25%',
    width: theme.spacing(4),
  },
  '@keyframes spin': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

export const Page = ({
  id,
  dataE2E = '',
  className = '',
  description,
  isLoading = false,
  noIndex,
  title,
  titleFull,
  children,
  ...props
}: PageProps) => {
  if (!title && !titleFull) {
    throw new Error('Page requires either a title or titleFull property');
  }
  const classes = useStyles();
  const fullTitle = titleFull || `WellSaid Labs – ${title}`;
  return (
    <div
      id={id}
      data-e2e={dataE2E}
      className={clsx('page', { loading: isLoading }, classes.root, className)}
      {...props}
    >
      <Head>
        <title>{fullTitle}</title>
        <meta property="og:title" content={fullTitle} />
        {description && (
          <meta property="og:description" content={description} />
        )}
        {description && <meta name="description" content={description} />}
        {noIndex && <meta name="robots" content="noindex" />}
      </Head>
      {isLoading ? <div className={classes.loadingIcon} /> : children}
    </div>
  );
};

type Title =
  | { title: string; titleFull?: undefined }
  | { title?: undefined; titleFull: string };

export type PageProps = Title &
  Omit<React.HTMLProps<HTMLDivElement>, 'title'> & {
    id: string;
    dataE2E?: string;
    className?: string;
    description?: string;
    isLoading?: boolean;
    noIndex?: boolean;
  };

export default Page;
