import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  DropdownMenuSeparatorProps,
  Separator,
} from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import { paletteV2 } from '../../../theme/palette';

export const useStyles = makeStyles(theme => ({
  separator: {
    backgroundColor: paletteV2.grey[600],
    height: 1,
    margin: 5,
  },
}));

interface MenuSeparatorProps extends DropdownMenuSeparatorProps {}

const MenuSeparator = (props: MenuSeparatorProps) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  return <Separator className={clsx(classes.separator, className)} {...rest} />;
};

export default MenuSeparator;
