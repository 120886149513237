import {
  DropdownMenuItemProps,
  Item,
  MenuSubTriggerProps,
  SubTrigger,
} from '@radix-ui/react-dropdown-menu';
import React from 'react';

interface MenuItemProps extends DropdownMenuItemProps {
  children: React.ReactNode;
}

interface MenuItemSubTriggerProps extends MenuSubTriggerProps {
  children: React.ReactNode;
}

/**
 * Helper hook to manage hovering over menu items
 */
const useItemHover = ({
  onMouseEnter,
  onMouseLeave,
}: {
  onMouseEnter: React.MouseEventHandler<HTMLDivElement> | undefined;
  onMouseLeave: React.MouseEventHandler<HTMLDivElement> | undefined;
}) => {
  /**
   * Radix-ui does not implement a hover state, so creating one by setting
   * data-hovered attr on element when it's hovered on via mouse events
   */
  const handleItemHover = (e: React.MouseEvent, isHovered: boolean) => {
    e.currentTarget.setAttribute('data-hovered', `${isHovered}`);
  };

  const handleMouseEnter = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (onMouseEnter) onMouseEnter(e);
    handleItemHover(e, true);
  };

  const handleMouseLeave = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (onMouseLeave) onMouseLeave(e);
    handleItemHover(e, false);
  };

  return {
    handleMouseEnter,
    handleMouseLeave,
  };
};

/**
 * Sub trigger is used as a menu item with a nested dropdown menu that flies out of this menu item
 * Need to apply the same mouse events like a menu item to add proper hover styling
 */
export const MenuItemSubTrigger = React.forwardRef<
  HTMLDivElement,
  MenuItemSubTriggerProps
>((props, forwardRef) => {
  const { onMouseEnter, onMouseLeave, children, ...rest } = props;
  const { handleMouseEnter, handleMouseLeave } = useItemHover({
    onMouseEnter,
    onMouseLeave,
  });
  return (
    <SubTrigger
      ref={forwardRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}
    >
      {children}
    </SubTrigger>
  );
});

const MenuItem = React.forwardRef<HTMLDivElement, MenuItemProps>(
  (props, forwardRef) => {
    const { onMouseEnter, onMouseLeave, children, ...rest } = props;
    const { handleMouseEnter, handleMouseLeave } = useItemHover({
      onMouseEnter,
      onMouseLeave,
    });

    return (
      <Item
        ref={forwardRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...rest}
      >
        {children}
      </Item>
    );
  }
);

export default MenuItem;
